<template>
  <section class="invoice-preview-wrapper">
    <b-row class="invoice-preview">
      <b-col cols="12" xl="9" md="8">
        <b-card no-body class="invoice-preview-card">
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">
            <!-- Header: Left Content -->
            <!-- <div>
                    <div class="logo-wrapper">
                      <logo />
                      <h3 class="text-primary invoice-logo">ELP</h3>
                    </div>
                    <p class="card-text mb-25">Office 149, 450 South Brand Brooklyn</p>
                    <p class="card-text mb-25">San Diego County, CA 91905, USA</p>
                    <p class="card-text mb-0">+1 (123) 456 7891, +44 (876) 543 2198</p>
                  </div> -->

            <!-- Header: Right Content -->
            <div class="mt-md-0 mt-2">
              <h4 class="invoice-title">
                Invoice
                <span class="invoice-number"
                  >: {{ transaksiId.merchant_ref }}</span
                >
              </h4>
              <div class="invoice-date-wrapper">
                <p class="invoice-date-title">Status</p>
                <p class="invoice-date">
                  :
                  <b-badge
                    v-if="transaksiId.status == 'PAID'"
                    variant="success"
                    >{{ transaksiId.status }}</b-badge
                  >
                  <b-badge
                    v-else-if="transaksiId.status == 'UNPAID'"
                    variant="warning"
                    >{{ transaksiId.status }}</b-badge
                  >
                  <b-badge v-else variant="danger">{{
                    transaksiId.status
                  }}</b-badge>
                </p>
              </div>
              <div class="invoice-date-wrapper">
                <p class="invoice-date-title">Tanggal</p>
                <p class="invoice-date">
                  : {{ humanDate(transaksiId.created_at) }}
                </p>
              </div>
            </div>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing" />

          <!-- Invoice Client & Payment Details -->
          <b-card-body class="invoice-padding pt-0">
            <b-row class="invoice-spacing">
              <!-- Col: Invoice To -->
              <b-col cols="12" xl="6" class="p-0">
                <div>
                  <h6 class="mb-2">Konsumen :</h6>
                  <table>
                    <tbody>
                      <tr>
                        <td class="pr-1">{{ transaksiId.customer_name }}</td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          {{ transaksiId.customer_phone }}
                        </td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          <p>{{ transaksiId.customer_email }}</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-col>

              <!-- Col: Payment Details -->
              <b-col
                cols="12"
                xl="6"
                class="p-0 mt-xl-0 d-flex justify-content-xl-end"
              >
                <div>
                  <h6 class="mb-2">Rincian Pembayaran :</h6>
                  <table>
                    <tbody>
                      <tr>
                        <td class="pr-1">Metode</td>
                        <td>: {{ transaksiId.payment_name }}</td>
                      </tr>
                      <tr>
                        <td class="pr-1">Tipe</td>
                        <td>
                          :
                          {{
                            transaksiId.payment_type == "payment_gateway"
                              ? "Payment Gateway"
                              : " Manual"
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <b-table-lite responsive :fields="fields" :items="transaksiData">
            <!-- A virtual column -->
            <template #cell(index)="data">
              {{ data.index + 1 }}
            </template>
            <!-- A virtual composite column -->
            <template #cell(price)="data">
              {{ formatRupiah(data.value) }}
            </template>
            <template #cell(subtotal)="data">
              {{ formatRupiah(data.item.price * data.item.quantity) }}
            </template>

            <!-- Optional default data cell scoped slot -->
            <template #cell()="data">
              {{ data.value }}
            </template>
          </b-table-lite>

          <!-- Invoice Description: Total -->
          <b-card-body class="invoice-padding pb-0">
            <b-row>
              <!-- Col: Sales Persion -->
              <b-col
                cols="12"
                md="6"
                class="mt-md-0 mt-3"
                order="2"
                order-md="1"
              >
                <b-card-text class="mb-0">
                  <span class="font-weight-bold">Note:</span>
                  <span class="ml-75">{{
                    transaksiId.note != null ? transaksiId.note : "-"
                  }}</span>
                </b-card-text>
              </b-col>

              <!-- Col: Total -->
              <b-col
                cols="12"
                md="6"
                class="mt-md-6 d-flex justify-content-end"
                order="1"
                order-md="2"
              >
                <div class="invoice-total-wrapper">
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">Subtotal:</p>
                    <p class="invoice-total-amount">
                      Rp.{{ formatRupiah(transaksiId.amount_received) }}
                    </p>
                  </div>
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">Discount:</p>
                    <p class="invoice-total-amount">
                      Rp.{{
                        transaksiId.discount != null
                          ? formatRupiah(transaksiId.discount)
                          : 0
                      }}
                    </p>
                  </div>
                  <!-- <div class="invoice-total-item">
                    <p class="invoice-total-title">Tax:</p>
                    <p class="invoice-total-amount">21%</p>
                  </div> -->
                  <hr class="my-50" />
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">Total:</p>
                    <p class="invoice-total-amount">
                      Rp.{{ formatRupiah(transaksiId.amount_received) }}
                    </p>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing" />

          <!-- Note -->
          <b-card-body class="invoice-padding pt-0">
            <span class="font-weight-bold">Instruksi: </span>
            <!-- <span>{{
              transaksiId.instructions != null ? transaksiId.instructions : "-"
            }}</span> -->
          </b-card-body>
        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <b-col cols="12" md="4" xl="3" class="invoice-actions">
        <b-card>
          <!-- Button: DOwnload -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-primary"
            class="mb-75"
            block
            :to="{ name: 'admin-transaksi-ebook' }"
          >
            <feather-icon icon="ArrowLeftIcon" class="mr-20" />
            Kembali
          </b-button>

          <!-- Button: Print -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-info"
            class="mb-75"
            block
            @click="printInvoice"
          >
            <feather-icon icon="PrinterIcon" class="mr-20" />
            Print
          </b-button>
          <b-button
            v-if="
              transaksiId.payment_type == 'manual' &&
              transaksiId.status == 'UNPAID'
            "
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-success"
            class=""
            block
            @click="confirmPayment"
            ><feather-icon icon="CheckSquareIcon" class="mr-20" />
            Konfirmasi Pembayaran
          </b-button>
          <b-button
            v-if="
              transaksiId.payment_type == 'manual' &&
              transaksiId.status == 'UNPAID'
            "
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-danger"
            class="mb-75"
            block
            @click="HapusBuktiTF"
          >
            <feather-icon icon="TrashIcon" class="mr-20" />
            Hapus Bukti Transfer
          </b-button>
          <b-button
            v-if="transaksiId.status == 'UNPAID'"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-danger"
            class="mb-75"
            block
            @click="CancelOrder"
          >
            <feather-icon icon="XCircleIcon" class="mr-20" />
            Batalkan Order
          </b-button>
        </b-card>
        <!-- </b-col>
      <b-col cols="12" md="4" xl="3" class="invoice-actions"> -->
        <b-card v-if="transaksiId.payment_type == 'manual'">
          <b-card-text
            ><b>Bukti Transfer</b>
            <br />
            <small v-if="transaksiIdOrder != null"
              >(klik pada gambar untuk lihat gambar lebih jelas)</small
            >
            <small v-if="transaksiIdOrder == null"
              >(belum tersedia bukti transfer)</small
            >
          </b-card-text>
          <div class="images" v-viewer>
            <b-img
              :src="
                transaksiIdOrder != null
                  ? apiFile + transaksiIdOrder.file
                  : require('@/assets/images/elp/no-image.jpg')
              "
              class="card-img mb-2"
            >
            </b-img>
          </div>
          <hr />
          <b-card-text>
            <!-- <b-col cols="12" xl="6" class=""> -->
            <div>
              <h6 class="mb-2">Rekening :</h6>
              <table>
                <tbody>
                  <tr>
                    <td class="pr-1">Kode</td>
                    <td>
                      :
                      {{
                        transaksiIdOrder != null
                          ? transaksiIdOrder.rekening.code
                          : "-"
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td class="pr-1">Nama Bank</td>
                    <td>
                      :
                      {{
                        transaksiIdOrder != null
                          ? transaksiIdOrder.rekening.name
                          : "-"
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td class="pr-1">Nomor Rek.</td>
                    <td>
                      :
                      {{
                        transaksiIdOrder != null
                          ? transaksiIdOrder.rekening.number
                          : "-"
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- </b-col> -->
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BImg,
  BRow,
  BCol,
  BTable,
  BCard,
  BCardText,
  BCardTitle,
  BTableLite,
  BCardBody,
  BPagination,
  BProgress,
  BBadge,
  BButton,
  BModal,
  VBModal,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BFormRadioGroup,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "viewerjs/dist/viewer.css";
import VueViewer from "v-viewer";
import Vue from "vue";
Vue.use(VueViewer);

export default {
  components: {
    VueViewer,
    BImg,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
    BCardBody,
    BTable,
    BTableLite,
    BPagination,
    BProgress,
    BBadge,
    BButton,
    BModal,
    VBModal,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    BFormRadioGroup,
    VBTooltip,
    ToastificationContent,
  },
  directives: {
    "b-modal": VBModal,
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      id: null,
      activeAction: null,
      label: "Simpan",
      //Data
      transaksiData: [],
      transaksiId: {
        name: null,
      },
      transaksiIdOrder: {},
      form: {
        id: null,
        name: null,
      },
      //Modal
      titleModal: null,
      Modal: false,
      ModalEdit: false,
      //Table
      fields: [
        // { key: "index", label: "No" },
        { key: "name", label: "Deskripsi" },
        { key: "price", label: "Harga" },
        { key: "quantity", label: "Jumlah" },
        { key: "subtotal", label: "Total" },
        // { key: "action", label: "#" },
      ],
      selected: [],
      total: [],
      record: [],
      optFilter: [
        { id: "name", value: "Transaksi" },
        // { id: "nip", value: "NIP" },
      ],
      perPage: 10,
      pageOptions: [10, 30, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      // filter: "Transaksi",
      search: null,
      idd: null,
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  setup() {
    const printInvoice = () => {
      window.print();
    };
    return {
      printInvoice,
    };
  },
  methods: {
    show() {
      this.$viewerApi({
        images: this.apiFile + this.transaksiIdOrder.file,
      });
    },
    confirmPayment() {
      this.$swal({
        title: "Anda Yakin",
        text: "Data transaksi ini sudah lunas?",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Batal",
        confirmButtonText: "Ya, Lunas!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          // let payload = {
          //   id: this.transaksiIdOrder.id,
          // };
          this.$store
            .dispatch(
              "transaksiManual/confirmPayment",
              this.transaksiIdOrder.id
            )
            .then(() => {
              this.getDataById();
              this.displaySuccess({
                text: "Data berhasil lunas",
              });
              this.$swal({
                icon: "success",
                title: "Konfirmasi Pembayaran!",
                text: "Data berhasil lunas.",
                // timer: 1000,
                allowOutsideClick: true,
                customClass: {
                  confirmButton: "btn btn-success",
                },
                buttonsStyling: false,
              });
            })
            .catch((e) => {
              this.displayError(e);
              return false;
            });
        } else if (result.dismiss === "cancel") {
          this.$swal({
            title: "Batal",
            text: "Data belum lunas, kamu batal konfirmasi pembayaran",
            icon: "error",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        }
      });
    },
    CancelOrder() {
      this.$swal({
        title: "Anda Yakin?",
        text: "Membatalkan order ini",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Tidak",
        confirmButtonText: "Ya!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          let payload = {
            order_id: this.transaksiId.order_id,
          };
          this.$store
            .dispatch("transaksiManual/CancelOrder", payload)
            .then(() => {
              this.getDataById();
              this.displaySuccess({
                text: "Order berhasil batalkan",
              });
              this.$swal({
                icon: "success",
                title: "Batalkan Order!",
                text: "Order berhasil batalkan.",
                // timer: 1000,
                allowOutsideClick: true,
                customClass: {
                  confirmButton: "btn btn-success",
                },
                buttonsStyling: false,
              });
            })
            .catch((e) => {
              this.displayError(e);
              return false;
            });
        } else if (result.dismiss === "cancel") {
          this.$swal({
            title: "Tidak!",
            text: "Data aman, kamu tidak jadi membatalkan order :)",
            icon: "error",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        }
      });
    },
    HapusBuktiTF() {
      this.$swal({
        title: "Anda Yakin?",
        text: "Data tidak bisa dikembalikan!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Batal",
        confirmButtonText: "Ya, Hapus!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          let payload = {
            id: this.transaksiIdOrder.id,
            fungsi: 2,
          };
          this.$store
            .dispatch("transaksiManual/deleteBuktiTF", payload)
            .then(() => {
              this.getDataById();
              this.displaySuccess({
                text: "Data berhasil dihapus",
              });
              this.$swal({
                icon: "success",
                title: "Terhapus!",
                text: "Data berhasil dihapus.",
                // timer: 1000,
                allowOutsideClick: true,
                customClass: {
                  confirmButton: "btn btn-success",
                },
                buttonsStyling: false,
              });
            })
            .catch((e) => {
              this.displayError(e);
              return false;
            });
        } else if (result.dismiss === "cancel") {
          this.$swal({
            title: "Batal",
            text: "Data aman, kamu batal menghapus data :)",
            icon: "error",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        }
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    ModalShow() {
      this.id = null;
      this.activeAction = "tambah";
      this.titleModal = "Tambah Transaksi";
      this.Modal = true;
      // this.ModalEdit = false;
    },
    ModalUbah(item) {
      this.id = item.id;
      this.form = item;
      this.activeAction = "ubah";
      this.titleModal = "Ubah Transaksi";
      this.Modal = true;
      // this.ModalEdit = true;
    },
    ModalHapus(data) {
      this.$swal({
        title: "Anda Yakin?",
        text: "Data tidak bisa dikembalikan!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Batal",
        confirmButtonText: "Ya, Hapus!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          data.fungsi = 2; //soft delete
          this.$store
            .dispatch("jurusan/createUpdateDelete", [data])
            .then(() => {
              this.getDataById();
              this.displaySuccess({
                text: "Data berhasil dihapus",
              });
              this.$swal({
                icon: "success",
                title: "Terhapus!",
                text: "Data berhasil dihapus.",
                // timer: 1000,
                allowOutsideClick: true,
                customClass: {
                  confirmButton: "btn btn-success",
                },
                buttonsStyling: false,
              });
            })
            .catch((e) => {
              this.displayError(e);
              return false;
            });
        } else if (result.dismiss === "cancel") {
          this.$swal({
            title: "Batal",
            text: "Data aman, kamu batal menghapus data :)",
            icon: "error",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        }
      });
    },
    pesanGagal() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Gagal menyimpan data, harap cek kembali data yang diisi",
            variant: "warning",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    Tambah() {
      if (this.form.merchant_ref == null || this.form.merchant_ref == "") {
        this.pesanGagal();
        return false;
      }
      let payload = {
        name: this.form.merchant_ref,
        school_id: this.$route.params.id,
      };

      if (this.id) {
        payload.id = this.id;
      }

      this.$store
        .dispatch("transaksi/createUpdateDelete", [payload])
        .then(() => {
          if (this.activeAction == "tambah") {
            this.id = null;
          }
          this.form.merchant_ref = null;
          this.Modal = false;
          // this.ModalEdit = false;
          this.getDataById();
          this.displaySuccess({
            text: "Transaksi berhasil di" + this.activeAction,
          });
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    async getDataById() {
      this.$store
        .dispatch("transaksi/indexId", this.$route.params.id)
        .then((response) => {
          let transaksiId = response.data;
          this.transaksiId = transaksiId;
          this.transaksiData = transaksiId.order_items;
          this.getDataByOrder();
        })
        .catch((error) => {
          this.$root.$emit("errorData", error);
        });
    },
    async getDataByOrder() {
      const payload = {
        // sekolah_id: localStorage.getItem("sekolah"),
        // filter: this.filter !== null ? this.filter.id : "name",
        // search: this.search,
        // start: (this.currentPage - 1) * this.itemsPerPage,
        // length: this.itemsPerPage,
        // payment_type: "manual",
        order_id: this.transaksiId.order_id,
      };
      this.$store
        .dispatch("transaksiManual/index", payload)
        .then((response) => {
          let transaksiIdor = response.data.data;
          this.transaksiIdOrder = transaksiIdor[0];
          // this.transaksiData = transaksiId.order_items;
        })
        .catch((error) => {
          this.$root.$emit("errorData", error);
        });
    },
  },
  created() {},
  mounted() {
    // this.getDatatransaksi();
    this.getDataById();
  },
};
</script>
<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media print {
  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
